//Libraries
import React from 'react';
import Helmet from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import { useLocation, useParams } from 'react-router';

//Types
import { CANONICAL_HOMEPAGE_URL as DOMAIN_NAME } from 'shared/constants/pages';

// text constants
import { DEFAULT_PREFIX_FOR_SLUG } from 'shared/routes/LandingPage/constants';
import { BRAND_LOGO, GLOBAL_BRAND_NAME } from 'shared/constants';

//helpers
import { useDomainConfig } from 'shared/domainConfig/context/context';

interface IMetaDataProps {
  metaData: {
    title: string;
    description: string;
    keywords: string;
    imageUrl?: string;
  };
}

const MetaInfo = ({ metaData }: IMetaDataProps) => {
  const { DEFAULT_META } = useDomainConfig();

  if (isEmpty(metaData)) {
    metaData = DEFAULT_META;
  }
  const { slug } = useParams<{ slug: string }>();

  const { description = '', title = '', keywords = '', imageUrl = BRAND_LOGO } = metaData;

  const { pathname, search } = useLocation();

  let CANONICAL_URL = new URL(DOMAIN_NAME + pathname);

  if (pathname.indexOf('/lp/') > -1) {
    const params = new URLSearchParams(search);
    const pageType = params.get('pageType');
    const pageVariation = params.get('pageVariation');

    if (pageType && pageType !== 'null') {
      const modifiedSlug = DEFAULT_PREFIX_FOR_SLUG + slug;

      if (modifiedSlug !== pageType) {
        CANONICAL_URL.searchParams.set('pageType', pageType);
      }
    }

    if (pageVariation && pageVariation !== 'null') {
      CANONICAL_URL.searchParams.set('pageVariation', pageVariation);
    }
  }

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={GLOBAL_BRAND_NAME} />
      <meta property="og:url" content={CANONICAL_URL.toString()} />
      <meta property="og:image" content={imageUrl} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />

      <link rel="canonical" href={CANONICAL_URL.toString()} />
    </Helmet>
  );
};

export default MetaInfo;
